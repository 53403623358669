import api from "./index"

/**
 *  get Create parsed post
 *
 * @param params
 * @return {*}
 */
export const getCreateParsedPostRequest = params => {
  return api
      .request(`admin/parser/${params.resource}/post`)
      .withParams(params)
      .get()
}

/**
 *  get Edit parsed post
 *
 * @param id
 * @return {*}
 */
export const getEditParsedPostRequest = id => {
  return api
      .request(`admin/parser/post`)
      .withParams(id)
      .get()
}

/**
 * Create parsed post
 *
 * @param data
 * @param includes
 * @return {*}
 */
export const createParsedPostRequest = (data, includes = []) => {
  return api
      .request(`admin/parser/${includes[0]}/post`)
      .withHeaders({
        "Content-type": "multipart/form-data"
      })
      .withBody(data, true)
      .withIncludes(includes)
      .post()
}

/**
 * update parsed post
 *
 * @param data
 * @param includes
 * @return {*}
 */
export const updateParsedPost = (data, includes = []) => {
  return api
      .request("admin/parser/post-update")
      .withHeaders({
          "Content-type": "multipart/form-data"
      })
      .withBody(data, true)
      .withIncludes(includes)
      .post()
}

/**
 * decline parsed post
 *
 * @param params
 *
 *
 * @return {*}
 */
export const declineParsedPost = params => {
  return api
      .request(`admin/parser/${params.resource}/post`)
      .withParams(params)
      .delete()
}