<template>
  <div>
    <div class="headline my-3">
      <span class="mr-2">Language:</span>
      <span class="lang">{{ item.language.language }}</span>
    </div>
    <validation-observer ref="validationObserver">
      <v-form ref="form" @submit.prevent="submit()">
        <div class="mb-2">
          <div class="label mb-1">
            Title:
          </div>
          <div>
            <validation-text-area
              v-model.trim="item.title"
              class="l-grey"
              label="No data"
              type="text"
              :success="false"
              name="name"
              :rules="{ required:true,max:255}"
              counter="255"
              solo
              box
              rows="4"
              required
              :error-messages="errors.collect('title')"
            />
          </div>
        </div>
        <div class="mb-2 mt-3">
          <div class="label mb-1">
            Description:
          </div>
          <div>
            <validation-text-area
              v-model.trim="item.description"
              class="l-grey"
              label="No data"
              type="text"
              :success="false"
              name="name"
              :rules="{ max:60000}"
              counter="60000"
              solo
              box
              rows="4"
              :error-messages="errors.collect('description')"
            />
          </div>
          <div class="mb-2">
            <v-layout justify-space-between row>
              <v-flex xs5>
                <div class="label mb-1">
                  Tags limit is 6, Character limit is 30
                </div>
                <div class="post-select">
                  <v-combobox
                    ref="combobox"
                    v-model="selectedTags"
                    :loading="loading"
                    :items="tagsDefaultList"
                    :search-input.sync="search"
                    :menu-props="{
                      contentClass:'add-select-tag-drop elevation-0',
                      value: autoSelectMenu,
                    }"
                    :error="tagError"
                    :disabled="disabledAddTag"
                    no-filter
                    append-icon="mdi-plus"
                    label="Add tag"
                    multiple
                    chips
                    solo
                    box
                    hide-selected
                    class="add-select-tag outlined-select bg-select"
                    @blur="blurInputTag()"
                    @input="addInputTag()"
                    @click:append="addButtonTag()"
                  >
                    <template v-slot:no-data>
                      <div class="no-tag">
                        There are no tags in your avoid list
                      </div>
                    </template>
                  </v-combobox>
                </div>
              </v-flex>
              <v-flex xs7>
                <div class="post-tags-list">
                  <ul class="tags-list">
                    <li
                      v-for="(tag, tagIndex) in selectedTags"
                      :key="`tag-${tag.id}-${tagIndex}`"
                      class="tags-item"
                    >
                      <div class="tag">
                        <span class="tag-name" v-text="tag"></span>
                        <v-btn
                          small
                          text
                          icon
                          class="btn-close"
                          @click="deleteTag(tag)"
                        >
                          <v-icon>mdi-close-circle-outline</v-icon>
                        </v-btn>
                      </div>
                    </li>
                  </ul>
                </div>
              </v-flex>
            </v-layout>
          </div>
          <div class="content-item btn-block mt-3 pt-3">
            <v-btn
              color="indigo"
              class="ma-0"
              @click="back"
            >
              <span>Edit post</span>
            </v-btn>
            <v-btn
              :loading="isLoading"
              color="success"
              class="ma-0 ml-2"
              type="submit"
            >
              <span>Save Translate</span>
            </v-btn>
          </div>
        </div>
      </v-form>
    </validation-observer>
  </div>
</template>

<script>
import {updateParsedPostRequest} from "../../api/parsedPostLanguages"
import {axios} from "../../utils/axios"
import { apiUrl } from "../../config"

export default {
  name: "PostTranslate",
  props: {
    item: {
      type: [Object],
      default: null
    }
  },
  data: () => ({
    isLoading: false,
    loading: false,
    selectedTags: [],
    tagsDefaultList: [],
    search: null,
    tagError: false,
    inputTagsLength: 6,
    inputTagsSymbolLength: 30,
  }),
  computed: {
    autoSelectMenu () {
      return !!((this.search && this.search.length >= 2) &&
          (this.selectedTags && this.selectedTags.length < this.inputTagsLength))
    },
    disabledAddTag () {
      return !!(this.selectedTags && this.selectedTags.length >= this.inputTagsLength)
    },
  },
  watch: {
    item(newVal, oldVal){
      if (newVal !== oldVal){
        this.selectedTags = this.item.tags.map((tag) => {
          return tag.title
        })
      }
    },
    search(newVal, oldVal){
      if (newVal !== oldVal){
        this.searchInput(newVal)
      }
    }
  },

  created() {
    this.selectedTags = this.item.tags.map((tag) => {
      return tag.title
    })
  },

  methods: {
    back(){
      this.$emit('back-to-post')
    },
    async submit () {
      const valid = await this.$refs.validationObserver.validate()
      if (!valid) {
        return
      }
      this.isLoading = true
      let formData = new FormData()
      formData.append("post_language_id", this.item.id)
      formData.append("title", this.item.title.trim())
      if(this.item.description && this.item.description.length){
        formData.append("description", this.item.description)
      }
      for (let i = 0; i < this.selectedTags.length; i++) {
        formData.append("tags[]", this.selectedTags[i])
      }
      updateParsedPostRequest(formData)
          .then(() => {
            this.$notificationShow("Translate updated!", 2000)
          })
          .catch(this.$notificationHandleResponseError)
          .finally(() => {this.isLoading = false})
    },
    // required(value) {
    //   if (value instanceof Array && value.length == 0) {
    //     return "The tags field is required."
    //   }
    //   return !!value || "The tags field is required."
    // },
    blurInputTag () {
      this.search = ''
    },
    searchInput () {
      if (this.search && this.search.length) {
        if (this.search.length >= this.inputTagsSymbolLength + 1) {
          this.$nextTick(() =>{
            this.search = this.search.slice(0, this.inputTagsSymbolLength)
          })
          // this.$refs.combobox.internalSearch = this.search.slice(0, this.inputTagsSymbolLength)
        }
        const regexps = /  +/g
        const results = regexps.test(this.search)
        if (results) {
          this.$nextTick(() =>{
            this.search = this.search.slice(0, -1)
          })
          // this.$refs.combobox.internalSearch = this.search.slice(0, -1)
        }
      }
      if (this.search && this.search.length > 1) {
        clearTimeout(this.inputSearchTimer)
        this.inputSearchTimer = setTimeout(() => {
          if (this.isLoading) { return }
          this.loading = true
          axios.get(`${apiUrl}tags?search=${this.search}`)
              .then((res) => {
                this.tagsDefaultList = res.data.data.map((tag) => {
                  return tag.title
                })
              })
              .catch((e) => {
                console.log(e)
              })
              .finally(() => {
                this.loading = false
                this.$nextTick(() => this.$refs.combobox.updateMenuDimensions())
              })
        }, 1000)
      }
    },
    unique (arr) {
      const result = []
      for (const str of arr) {
        if (!result.includes(str) && str.length) {
          result.push(str)
        }
      }
      return result
    },
    addInputTag () {
      this.tagError = false
      if (this.search && this.search.length && typeof this.search === 'string') {
        const index = this.selectedTags.indexOf(this.search)
        const str = this.search.trim()
        this.selectedTags[index] = str
        this.selectedTags = this.unique(this.selectedTags)
        if (this.selectedTags.length >= this.inputTagsLength) {
          this.search = null
          setTimeout(() => {
            this.$refs.combobox.isFocused = false
          }, 0)
        }
      }
    },
    addButtonTag () {
      if (this.$refs.combobox.internalSearch && this.$refs.combobox.internalSearch.length) {
        this.search = this.$refs.combobox.internalSearch.trim()
      }
      if (this.selectedTags.includes(this.search)) { this.search = null }
      if (
          !this.selectedTags.includes(this.search) &&
          this.search !== '' &&
          this.search &&
          this.search.length <= this.inputTagsSymbolLength &&
          typeof this.search === 'string'
      ) {
        this.selectedTags.push(this.search)
        this.tagError = false
        this.search = null
      }
      if (this.selectedTags.length >= this.inputTagsLength) {
        setTimeout(() => {
          this.$refs.combobox.isFocused = false
        }, 0)
      }
    },
    deleteTag (tag) {
      this.selectedTags.includes(tag)
          ? this.selectedTags.splice(this.selectedTags.indexOf(tag), 1)
          : this.selectedTags.push(tag)
    }
  }
}
</script>

<style scoped>
.btn-block{
  border-top: 1px solid #969696;
}
.btn-block{
  display: flex;
  justify-content: flex-end;
}
.headline .lang{
  color: #ff5722;
}

</style>