<template>
  <div class="post-wrap">
    <v-layout row wrap>
      <v-flex sm12 md2 lg1>
        <v-btn flat class="blue-grey ma-0" @click="$router.go(-1)">
          <v-icon left dark class="font-size-20">
            arrow_back
          </v-icon>
          Back
        </v-btn>
      </v-flex>
      <v-flex sm12 md10 lg11>
        <v-card v-if="!!post && Object.keys(post).length > 0" class="pa-3 post-container">
          <div v-if="showTranslateBlock" class="lang-list mb-3">
            <span class="headline mr-2">Translate:</span>
            <v-btn
              v-for="(item) in post.languages"
              :key="item.id"
              :loading="item.post_language_id === langLoading"
              :color="item.post_language_id === langActiveId ? 'deep-purple' : 'deep-orange'"
              class="mr-1 mb-1"
              @click="showTranslateLang(item.post_language_id)"
            >
              {{ item.language }}
            </v-btn>
          </div>
          <div v-if="!showTranslate" class="posts-list-item">
            <validation-observer ref="validationObserver">
              <v-form ref="form" @submit.prevent="submit()">
                <v-layout justify-space-between class="post-top">
                  <v-flex sm12 md6 lg6>
                    <div class="headline">
                      <router-link :to="`/moderation/user_list/user/${post.user.id}`" class="user-link">
                        <v-avatar v-if="post.user.image" size="36px">
                          <img :src="post.user.image" alt="">
                        </v-avatar>
                        <v-avatar v-else size="36px">
                          <v-icon class="no-avatar-icon">
                            mdi-account
                          </v-icon>
                        </v-avatar>

                        <div
                          class="title text--darken-1 ml-3 wrap-text break"
                          v-text="post.user.username"
                        ></div>
                      </router-link>
                    </div>
                  </v-flex>
                  <v-flex sm12 md6 lg6>
                    <template v-if="post.channel">
                      <div v-if="showChannel" class="headline">
                        <div class="label mr-3">
                          Channel:
                        </div>
                        <div class=" break">
                          {{ post.channel.name }}
                        </div>
                      </div>
                      <div v-else class="headline">
                        <div class="label mr-3">
                          Link:
                        </div>
                        <a :href="post.channel.link" class=" break" target="_blank">
                          {{ post.channel.link }}
                        </a>
                      </div>
                    </template>
                  </v-flex>
                </v-layout>
                <div class="post-top mb3">
                </div>
                <div class="mb-2">
                  <div class="label mb-1">
                    Title:
                  </div>
                  <div>
                    <validation-text-area
                      v-model.trim="post.title"
                      class="l-grey"
                      label="No data"
                      type="text"
                      :success="false"
                      name="name"
                      :rules="{ required:true,max:255}"
                      counter="255"
                      solo
                      box
                      rows="4"
                      required
                      :error-messages="errors.collect('title')"
                    />
                  </div>
                </div>
                <!--                <template v-if="post.chunks && post.chunks.length">-->
                <div v-for="(chunk, chunkIndex) in post.chunks"
                     :key="`chunk-${chunk.id}-${chunkIndex}`"
                >
                  <!--                    <template v-if="chunk.contents && chunk.contents.length">-->
                  <div class="mb-2 mt-3">
                    <div class="label mb-1">
                      Description:
                    </div>
                    <div>
                      <validation-text-area
                        v-model.trim="chunk.description"
                        class="l-grey"
                        label="No data"
                        type="text"
                        :success="false"
                        name="name"
                        :rules="{ max:60000, required: requiredDesc}"
                        counter="60000"
                        solo
                        box
                        rows="6"
                        required
                        :error-messages="errors.collect('description')"
                      />
                    </div>
                  </div>
                  <div v-for="(content, contentIndex) in chunk.contents"
                       :key="`content-${content.id}-${contentIndex}`"
                       class="item"
                  >
                    <div v-if="content.type === 'image'" class="content-item mt-3 pt-3">
                      <div class="content-block mb-2">
                        <div class="text-right">
                          <v-btn
                            color="error"
                            class="ma-0 mb-1"
                            @click="deleteContent(chunkIndex, contentIndex, content.id)"
                          >
                            <span>Delete</span>
                          </v-btn>
                        </div>
                        <div>
                          <img :src="content.url" alt="">
                        </div>
                      </div>
                    </div>
                    <div v-else-if="content.type === 'video' || content.type === 'gif'" class="content-item mt-3 pt-3">
                      <div class="content-block mb-2">
                        <div class="text-right">
                          <v-btn
                            color="error"
                            class="ma-0 mb-1"
                            @click="deleteContent(chunkIndex, contentIndex, content.id)"
                          >
                            <span>Delete</span>
                          </v-btn>
                        </div>
                        <div>
                          <video
                            controls
                            :src="content.url"
                            type="video/mp4"
                          >
                          </video>
                        </div>
                      </div>
                    </div>
                    <div v-else-if="content.type === 'iframe'" class="content-item mt-3 pt-3">
                      <div class="content-block content-iframe mb-2">
                        <div class="text-right">
                          <v-btn
                            color="error"
                            class="ma-0 mb-1"
                            @click="deleteContent(chunkIndex, contentIndex, content.id)"
                          >
                            <span>Delete</span>
                          </v-btn>
                        </div>
                        <div class="iframe-block">
                          <iframe
                            :src="
                              `https://www.youtube.com/embed/${youtubeUrl(content.url)}`
                            "
                            frameborder="0"
                            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                            allowfullscreen
                          ></iframe>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!--                    </template>-->
                </div>
                <!--                </template>-->
                <div class="mb-2 mt-3">
                  <v-layout align-center justify-start row>
                    <v-flex xs6>
                      <v-checkbox
                        v-model="post.nsfw"
                        label="Sensitive Content"
                        class="checkbox-item ma-0 pa-0"
                        hide-details
                      />
                    </v-flex>
                    <v-flex xs6 class="text-xs-right">
                      {{ timePassed(post.date) }}
                    </v-flex>
                  </v-layout>
                </div>
                <div class="mb-2">
                  <v-layout justify-space-between row>
                    <v-flex xs5>
                      <div class="label mb-1">
                        Tags limit is 6, Character limit is 30
                      </div>
                      <div class="post-select">
                        <v-combobox
                          ref="combobox"
                          v-model="selectedTags"
                          :loading="loading"
                          :items="tagsDefaultList"
                          :search-input.sync="search"
                          :menu-props="{
                            contentClass:'add-select-tag-drop elevation-0',
                            value: autoSelectMenu,
                          }"
                          :error="tagError"
                          :disabled="disabledAddTag"
                          :rules="[required]"
                          no-filter
                          append-icon="mdi-plus"
                          label="Add tag"
                          multiple
                          chips
                          solo
                          box
                          hide-selected
                          class="add-select-tag outlined-select bg-select"
                          @blur="blurInputTag()"
                          @input="addInputTag()"
                          @click:append="addButtonTag()"
                        >
                          <template v-slot:no-data>
                            <div class="no-tag">
                              There are no tags in your avoid list
                            </div>
                          </template>
                        </v-combobox>
                        <!--                        <span v-if="tagError" class="tag-hint tag-hint-error">-->
                        <!--                          The tags field is required.-->
                        <!--                        </span>-->
                      </div>
                    </v-flex>
                    <v-flex xs7>
                      <div class="post-tags-list">
                        <ul class="tags-list">
                          <li
                            v-for="(tag, tagIndex) in selectedTags"
                            :key="`tag-${tag.id}-${tagIndex}`"
                            class="tags-item"
                          >
                            <div class="tag">
                              <span class="tag-name" v-text="tag"></span>
                              <v-btn
                                small
                                text
                                icon
                                class="btn-close"
                                @click="deleteTag(tag)"
                              >
                                <v-icon>mdi-close-circle-outline</v-icon>
                              </v-btn>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </v-flex>
                  </v-layout>
                </div>
                <v-layout align-center justify-start row>
                  <v-flex xs6>
                    <span>
                      <v-icon class="blue-grey--text mr-2">thumb_up</v-icon>
                      <span
                        class="teal--text bold font-size-18"
                        v-text="post.statistic.upvotes"
                      >
                      </span>
                    </span>

                    <span class="ml-3">
                      <v-icon class="blue-grey--text mr-2">thumb_down</v-icon>
                      <span
                        class="red--text bold font-size-18"
                        v-text="post.statistic.downvotes"
                      >
                      </span>
                    </span>
                  </v-flex>
                  <v-flex xs6 class="text-xs-right">
                    <span>
                      <v-icon class="blue-grey--text mr-2">videocam</v-icon>
                      <span
                        class="blue-grey--text bold font-size-18"
                        v-text="post.statistic.count_videos"
                      >
                      </span>
                    </span>

                    <span class="ml-3">
                      <v-icon class="blue-grey--text mr-2">panorama</v-icon>
                      <span
                        class="blue-grey--text bold font-size-18"
                        v-text="post.statistic.count_images"
                      >
                      </span>
                    </span>
                  </v-flex>
                </v-layout>
                <div class="content-item btn-block mt-3 pt-3">
                  <span v-if="post.status === 'in_queue'">In queue...</span>
                  <v-btn
                    v-if="post.status === 'publish'"
                    :loading="isLoading"
                    :disabled="disableSubmit"
                    color="success"
                    class="ma-0"
                    type="submit"
                  >
                    <span>Save and publish</span>
                  </v-btn>
                  <v-btn
                    :disabled="isLoading"
                    color="warning"
                    class="ma-0  ml-2"
                    @click="openDeletePostModal"
                  >
                    <span>Delete</span>
                  </v-btn>
                  <v-btn
                    :disabled="isLoading"
                    color="error"
                    class="ma-0 ml-2"
                    @click="openForeverDeleteModal"
                  >
                    <span>Delete forever</span>
                  </v-btn>
                </div>
              </v-form>
            </validation-observer>
          </div>
          <div v-else>
            <post-translate
              :item="translateItem"
              @back-to-post="backToPost()"
            />
          </div>
        </v-card>
      </v-flex>
    </v-layout>
    <delete-post-modal
      v-model="deletePostModal"
      :post-id="post.id"
      :reason="reason"
      @deleteCurrentPost="deleteCurrentPost"
    />
    <accept-modal
      v-model="showAcceptModal"
      :post-id="post.id"
      @accept="deleteForeverPost"
    />
  </div>
</template>

<script>
import { axios } from "../../utils/axios"
import { apiUrl } from "../../config"
import moment from "moment"
import {updateParsedPost} from "../../api/parsedPost"
import PostTranslate from './PostTranslate'
import {getParsedPostLanguage} from "../../api/parsedPostLanguages"
import DeletePostModal from "../Moderation/PostList/DeletePostModal"
import {getReasons} from "../../api/reason"
import mixinYoutubeUrl from "../../mixins/mixinYoutubeUrl"
import AcceptModal from "@/components/Modals/AcceptModal"

export default {
  name: "EditParsedPost",
  components: {
    PostTranslate,
    DeletePostModal,
    AcceptModal,
  },
  mixins: [mixinYoutubeUrl],
  props: {
    post: {
      type: Object,
      default: () => {}
    },
    prevRoute: {
      type: Object,
      default: () => {}
    },
    languages: {
      type: Array,
      default: () => []
    },
    supportedLanguages: {
      type: Array,
      default: () => []
    },
    getPostById: {
      type: Function,
      default: () => {},
      // default: null
    }
  },
  data() {
    return {
      isLoading: false,
      nsfw: false,
      languagesErrors: 'Select language',
      selectedLang: null,
      disableSubmit: true,
      loading: false,
      selectedTags: [],
      tagsDefaultList: [],
      inputTagsLength: 6,
      inputTagsSymbolLength: 30,
      search: null,
      tagError: true,
      translateLanguages: [],
      requiredDesc: false,
      deletedContents: [],
      showTranslate: false,
      translateItem: null,
      langLoading: null,
      langActiveId: null,
      deletePostModal: false,
      reason: [],
      showAcceptModal: false,
    }
  },
  computed: {
    autoSelectMenu () {
      return !!((this.search && this.search.length >= 2) &&
          (this.selectedTags && this.selectedTags.length < this.inputTagsLength))
    },
    disabledAddTag () {
      return !!(this.selectedTags && this.selectedTags.length >= this.inputTagsLength)
    },
    showTranslateBlock () {
      return !!(this.post.languages && this.post.languages.length)
    },
    showChannel () {
      return this.$route.params.name === 'telegram'
    }
  },
  watch: {
    // post(newVal, oldVal){
    //   console.log('asdasdasd')
    //   if (newVal !== oldVal){
    //     this.selectedTags = newVal.tags.map((tag) => {
    //       return tag.title
    //     })
    //     this.selectedLang = newVal.language.id
    //     this.translateLanguages = newVal.languages.map(item => item.id)
    //     // this.description = newVal.chunks[0].description
    //   }
    // },
    search(newVal, oldVal){
      if (newVal !== oldVal){
        this.searchInput(newVal)
      }
    }
  },
  created() {
    this.getReasonBlockUser()
  },
  mounted() {
    this.selectedTags = this.post.tags.map((tag) => {
      return tag.title
    })
    this.selectedLang = this.post.language.id
    this.translateLanguages = this.post.languages.map(item => item.id)
  },
  updated() {
    const contents = this.post.chunks[0].contents.map(item  => item.id)
    contents.length ? this.requiredDesc = false : this.requiredDesc = true
    if (!this.showTranslate){this.validatePost()}
  },
  methods:{
    openForeverDeleteModal(){
      this.showAcceptModal = true
    },
    deleteForeverPost(){
      this.$router.go(-1)
      this.$notificationShow(
          `The post is successfully deleted`
      )
    },
    async validatePost(){
      await this.$refs.combobox.validate() ? this.tagError = false: this.tagError = true
      const valid = await this.$refs.validationObserver.validate()
      this.disableSubmit = !(valid && !this.tagError)
    },
    timePassed(create) {
      return moment(create).fromNow()
    },
    async getReasonBlockUser() {
      this.reason = await getReasons()
          .then(response => {
            return response.data.data
          })
          .then(data =>
              data.map(reason => {
                return reason
              })
          )
          .catch(this.$notificationHandleResponseError)
    },
    openDeletePostModal(){
      this.deletePostModal = true
    },
    deleteCurrentPost(){
      if (this.prevRoute && this.prevRoute.fullPath) {
        this.$router.go(-1)
      } else {
        this.$router.push({name: 'parser_posts'})
      }
    },
    deleteContent(chunkIndex, contentIndex, id) {
      this.post.chunks[chunkIndex].contents.splice(contentIndex, 1)
      this.deletedContents.push(id)
    },
    backToPost() {
      this.showTranslate = false
      this.langActiveId = null
    },
    async showTranslateLang(id) {
      this.langLoading = id
      const params = {post_language_id: id}
      await getParsedPostLanguage(params)
          .then((res) => {
            this.translateItem = res.data.data
            this.langActiveId = id
            this.showTranslate = true
          })
          .catch(this.$notificationHandleResponseError)
          .finally(() => {
            this.langLoading = null
          })
    },
    async submit() {
      const valid = await this.$refs.validationObserver.validate()

      if (!valid || this.tagError) {
        return
      }
      this.errors.clear()
      let nsfw = this.post.nsfw ? 1 : 0
      let formData = new FormData()
      formData.append("post_id", this.post.id)
      formData.append("title", this.post.title.trim())
      if(this.post.chunks[0].description && this.post.chunks[0].description.length){
        formData.append("description", this.post.chunks[0].description)
      }
      formData.append("nsfw", `${nsfw}`)
      for (let i = 0; i < this.selectedTags.length; i++) {
        formData.append("tags[]", this.selectedTags[i])
      }
      for (let i = 0; i < this.deletedContents.length; i++) {
        formData.append("del_contents[]", this.deletedContents[i])
      }
      this.isLoading = true
      await updateParsedPost(formData)
          .then(() => {
            this.$notificationShow("Post is published!")
            this.getPostById()
          })
          .catch(this.$notificationHandleResponseError)
          .finally(() => {this.isLoading = false})
    },
    required(value) {
      // if (value.length > 10) {
      //   this.selectedTag.pop()
      // }
      if (value instanceof Array && value.length == 0) {
        return "The tags field is required."
      }
      return !!value || "The tags field is required."
    },
    blurInputTag () {
      this.search = ''
    },
    searchInput () {
      if (this.search && this.search.length) {
        if (this.search.length >= this.inputTagsSymbolLength + 1) {
          this.$nextTick(() =>{
            this.search = this.search.slice(0, this.inputTagsSymbolLength)
          })
          // this.$refs.combobox.internalSearch = this.search.slice(0, this.inputTagsSymbolLength)
        }
        const regexps = /  +/g
        const results = regexps.test(this.search)
        if (results) {
          this.$nextTick(() =>{
            this.search = this.search.slice(0, -1)
          })
          // this.$refs.combobox.internalSearch = this.search.slice(0, -1)
        }
      }
      if (this.search && this.search.length > 1) {
        clearTimeout(this.inputSearchTimer)
        this.inputSearchTimer = setTimeout(() => {
          if (this.isLoading) { return }
          this.loading = true
          axios.get(`${apiUrl}tags?search=${this.search}`)
              .then((res) => {
                  // let x = new Set(_.flatMap(res.data.data, 'translations')
                  //     .map(translations => translations.title))

                this.tagsDefaultList = res.data.data.map((tag) => {
                    return tag.title
                })
              })
              .catch((e) => {
                console.log(e)
              })
              .finally(() => {
                this.loading = false
                this.$nextTick(() => this.$refs.combobox.updateMenuDimensions())
              })
        }, 1000)
      }
    },
    unique (arr) {
      const result = []
      for (const str of arr) {
        if (!result.includes(str) && str.length) {
          result.push(str)
        }
      }
      return result
    },
    addInputTag () {
      this.tagError = false
      if (this.search && this.search.length && typeof this.search === 'string') {
        const index = this.selectedTags.indexOf(this.search)
        const str = this.search.trim()
        this.selectedTags[index] = str
        this.selectedTags = this.unique(this.selectedTags)
        if (this.selectedTags.length >= this.inputTagsLength) {
          this.search = null
          setTimeout(() => {
            this.$refs.combobox.isFocused = false
          }, 0)
        }
      }
    },
    addButtonTag () {
      if (this.$refs.combobox.internalSearch && this.$refs.combobox.internalSearch.length) {
        this.search = this.$refs.combobox.internalSearch.trim()
      }
      if (this.selectedTags.includes(this.search)) { this.search = null }
      if (
          !this.selectedTags.includes(this.search) &&
          this.search !== '' &&
          this.search &&
          this.search.length <= this.inputTagsSymbolLength &&
          typeof this.search === 'string'
      ) {
        this.selectedTags.push(this.search)
        this.tagError = false
        this.search = null
      }
      if (this.selectedTags.length >= this.inputTagsLength) {
        setTimeout(() => {
          this.$refs.combobox.isFocused = false
        }, 0)
      }
    },
    deleteTag (tag) {
      this.selectedTags.includes(tag)
          ? this.selectedTags.splice(this.selectedTags.indexOf(tag), 1)
          : this.selectedTags.push(tag)
    }
  }
}
</script>

<style scoped>
.theme--dark >>> .v-btn.v-btn--disabled:not(.v-btn--icon):not(.v-btn--flat){
  font-size: 14px;
}
>>> .v-input.error--text .v-label{
  color: red;
}
.content-item{
  display: flex;

}
.item{
  border-bottom: 1px solid #969696;
}
.item:first-child{
  border-top: 1px solid #969696;
}
.content-item.btn-block{
  border-top: 1px solid #969696;

  border-bottom: transparent;
}
.content-item.btn-block{
  justify-content: flex-end;
}
.content-block{
  text-align: center;
  margin: 0 auto;
}
.content-block img, .content-block video{
  max-height: 68vh;
}
.text-right{
  text-align: right;
}
.post-container{
  max-width: 1200px;
  /*margin: 0 auto;*/
}
.post-wrap{
  position: relative;
}
.label{
  font-size: 16px;
}
.headline{
  display: inline-flex;
  align-items: baseline;
}

.lang-wrap .label{
  text-align: left;
}
.post-top{
  display: flex;
}

.iframe-block iframe{
  pointer-events: auto;
  min-height: 560px;
}
.content-block.content-iframe{
  width: 100%;
}
</style>
